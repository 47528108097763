<template>
  <div class="container">
    <img src="@/assets/logo.svg" alt="" class="img-logo mb-5">
    <div class="card shadow">
      <div class="card-header">
         <h1 class="">PropertyPistol FB Auth</h1>
        <div class="d-flex justify-content-between">
          <button class="loginBtn loginBtn--facebook" @click="openFbLogin" v-if="!isLogged">
            Login with Facebook
          </button>
          <download-csv :data   = "exportablePages" class="btn btn-success" v-if="isLogged">
            <b-icon icon="download" style="padding: 2px;"></b-icon>
              Export
          </download-csv>
        </div>
      </div>
      <div class="card-body" v-if="isLogged">
        <table  class="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">ID</th>
                <th scope="col">Page</th>
                <th scope="col">Token</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, index) in pages" :key="index">
                <th scope="row">{{index + 1}}</th>
                <td>{{page.id}}</td>
                <td>{{page.name}}</td>
                <td>
                  <button style="color:white;" type="button" class="btn btn-info" v-clipboard:copy="page.access_token">Copy!</button>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      <div  v-if="isLogged" class="card-footer d-flex justify-content-end">
        <button type="button" class="btn btn-danger" @click="resetCookies">  <img src="@/assets/close.svg" alt="" class="mr-1">Reset All</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      pages: [],
      isLogged: false
    }
  },
  created(){
    this.seedFromCookies();
  },
  mounted(){
    window.fbAsyncInit = function() {
      FB.init({
        appId      : '946055405844107',
        xfbml      : true,
        version    : 'v10.0'
      });
    };
  },
  computed: {
    exportablePages(){
      let newData = []
      this.pages.forEach( page => {
        let newObj = {token: page.access_token, category: page.category, name: page.name, id: page.id}
        newData.push(newObj);
      })
      return newData;
    }
  },
  methods: {
    openFbLogin(){
      let vueApp = this;
      FB.login(function(response){
        FB.api('/me/accounts', function(response) {
          console.log('Api Response: ', response);
          if(response.data){
            let pages = response.data;
            pages.forEach(page => {
              vueApp.subscribeApp(page.id, page.access_token)
            });
            vueApp.$cookies.set('glitzLoggedIn', true);
            vueApp.$cookies.set('glitzFBPages', JSON.stringify(pages));
            vueApp.seedFromCookies();
          }else{
            alert(`Something Went Wrong. Please Try Again. ${response.error.message}`)
          }
        });
      }, {scope: ['pages_show_list', 'leads_retrieval', 'pages_manage_metadata', 'pages_manage_ads', 'pages_read_engagement', 'leads_retrieval', 'ads_management']});
    },
    seedFromCookies(){
      let loggedFlagC = this.$cookies.get('glitzLoggedIn')
      if(loggedFlagC !== null){
        this.isLogged = loggedFlagC
      }else{
        this.isLogged = false
      }
      let cookiePages = this.$cookies.get('glitzFBPages')
      if(cookiePages !== null){
        this.pages = JSON.parse(cookiePages)
      }else{
        this.pages = []
      }
    },
    resetCookies(){
      let vueApp = this
      if(confirm('Are you Sure?')){
        vueApp.$cookies.remove('glitzLoggedIn');
        vueApp.$cookies.remove('glitzFBPages');
        vueApp.seedFromCookies();
      }
    },
    subscribeApp(pageId, pageAccessToken){
      FB.api(
        '/' + pageId + '/subscribed_apps',
        'post',
        {access_token: pageAccessToken, subscribed_fields: ['leadgen']},
        function(response) {
          console.log('Successfully subscribed page', response);
        }
      );
    }
  }
}
</script>

<style scoped>
  .loginBtn {
    box-sizing: border-box;
    position: relative;
    /* width: 13em;  - apply for fixed size */
    margin: 0.2em;
    padding: 0 15px 0 46px;
    border: none;
    text-align: left;
    line-height: 34px;
    white-space: nowrap;
    border-radius: 0.2em;
    font-size: 16px;
    color: #FFF;
  }
  .loginBtn:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
  }
  .loginBtn:focus {
    outline: none;
  }
  .loginBtn:active {
    box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
  }


  /* Facebook */
  .loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
  }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
  }
  .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
  }
  .btn-success{
    font-size: 14px;
    padding: 8px 20px;
  }
  .btn-danger{
    font-size: 14px;
    padding: 8px 20px; 
  }
  .btn-danger img{
    margin-right: 5px;
  }
  .img-logo{
    width:180px;
    margin: 30px 0 0;
  }
  h1{
    font-size:20px;
  }
  .card{
    border: none;
    border-radius: 10px;
  }
  .card-header, .card-body, .card-footer{
    background: #fff;
    padding: 30px;
  }
  .table{
    font-size: 16px;
  }
  .table th{
    font-size: 14px;
  }
  .table td, .table th{
    vertical-align: middle;
  }
</style>
